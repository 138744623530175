button {
  background: none;
  border: none;
  cursor: pointer;
}

.credentials-btn {
  margin-top: var(--sm-space);
}

.credentials-btn:hover {
  color: var(--sembraCare-secondary);
  text-decoration: underline;
}

.forgot-credentials-btn-bar {
  display: flex;
  flex-direction: column;
}

.sc-btn {
  border: none;
  background: radial-gradient(
    var(--secondary-light) 15%,
    var(--sembraCare-secondary) 90%
  );
  color: var(--main-dark);
  border-radius: var(--xs-space);
  padding: var(--xs-space);
  min-width: 120px;
}

.disabled-btn {
  border: none;
  background: radial-gradient(
    var(--secondary-light) 15%,
    var(--sembraCare-secondary) 90%
  );
  color: var(--main-dark);
  border-radius: var(--xs-space);
  padding: var(--xs-space);
  min-width: 120px;
  opacity: 0.5;
  cursor: default;
}

.link-btn {
  background: none;
  text-decoration: underline;
}

@media screen and (max-width: 540px) {
  .sc-btn,
  .disabled-btn {
    min-width: 100%;
    padding: var(--sm-space);
    font-weight: 700;
  }
}

@media screen and (min-width: 992px) {
  .sc-btn:hover {
    background: radial-gradient(
      var(--secondary-light) 15%,
      var(--secondary-dark) 90%
    );
  }
}

.next-btn {
  width: calc(var(--lg-space) * 4);
}

.btn-bar {
  width: 100%;
  text-align: center;
}

.btn-group button {
  margin-bottom: var(--md-space);
}

@media screen and (min-width: 540px) {
  .btn-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: var(--lg-space);
  }
}

/* .btn-group p {
  margin-bottom: 0;
} */
