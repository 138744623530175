.version {
  position: absolute;
  font-size: var(--sm-space);
}

.loader-wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 12px solid #0000c2;
  border-radius: 50%;
  border-top: 12px solid #f3f3f3;
  width: 70px;
  height: 70px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.logout-icon {
  position: absolute;
  right: 5px;
  font-size: var(--lg-space);
}

.contact {
  color: var(--info-text);
  background: var(--info-background);
  padding: var(--sm-space);
  font-weight: 800;
}

/* .questions-contact {
  color: var(--info-text);
  background: var(--info-background);
  padding: var(--sm-space);
  font-weight: 800;
} */

.no-list-style li {
  list-style: none;
  line-height: 2rem;
}

.team-note {
  padding: var(--sm-space);
  font-weight: 700;
  margin-bottom: var(--lg-space);
  color: red;
}

/* .verification-error {
  background: var(--err-background);
  color: var(--err-text);
  font-weight: 700;
  margin: var(--md-space) 0;
  padding: var(--sm-space);
  border-radius: var(--xs-space);
}

.verification-success {
  background: var(--success-background);
  color: var(--success-text);
  font-weight: 700;
  margin: var(--md-space) 0;
  padding: var(--sm-space);
  border-radius: var(--xs-space);
} */

.input-validation {
  /* width: 50%; */
  margin-bottom: 0;
  position: absolute;
  /* text-align: left; */
  right: 45px;
  /* left: 5px; */
  bottom: 2px;
}

.input-success {
  color: green;
}

.input-error {
  color: var(--err-text);
}

.validation-err {
  outline: 1px solid red;
  box-shadow: 0 0 5px red;
}

.verification-message {
  font-weight: 700;
  margin: var(--md-space) 0;
  padding: var(--sm-space);
  border-radius: var(--xs-space);
}

.user-message {
  margin: var(--md-space) 0;
  padding: var(--sm-space);
  border-radius: var(--xs-space);

  display: flex;
  align-items: center;
}

.user-message p {
  display: block;
  margin: 0;
}

.user-message svg {
  margin-right: 1rem;
}

.information {
  background: var(--info-background);
  color: var(--info-text);
}

.error {
  color: #7a0000;
  background: #f8c9c9;
}

.success {
  background: var(--success-background);
  color: var(--success-text);
}

/* MODAL  */
.modal-wrapper {
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  background: whitesmoke;
  overflow-y: scroll;
}

@media screen and (min-width: 992px) {
  .modal-wrapper {
    padding: var(--xl-space), 0;
  }
}
.modal {
  position: relative;
  max-width: 768px;
  margin: auto;
}

.modal .close-btn {
  color: white;
  position: absolute;
  top: 10px;
  right: 45px;
  font-size: var(--lg-space);
  transition-property: color;
  transition-duration: 0.25s;
}

.modal .close-btn:hover {
  color: var(--sembraCare-secondary);
}

hr {
  margin: var(--md-space) 0;
  /* border-top: var(--sembraCare-secondary); */
  border-top: 1px solid var(--sembraCare-secondary);
  border-bottom: none;
}
