

.license-data {
  margin-bottom: var(--xl-space);
  background: white;
  padding: var(--sm-space);
  background: lightgray;
}

.npi-list {
  margin: 0 auto var(--md-space);
  display: flex;
  text-align: left;
  justify-content: space-around;
  /* max-width: 400px; */
  /* border-bottom: 1px solid rgb(66, 66, 66);
  border-top: 1px solid rgb(66, 66, 66);
  border: 1px solid #caa819; */
  box-shadow: 0 0 2px var(--sembraCare-main);
}

.keys-col {
  width: 35%;
}


 .list-values:nth-child(even) {     
  background: lightblue;
  padding: var(--sm-space);
  margin-bottom: 0;
}

.values-col {
  width: 80%;
}

.list-key:nth-child(odd), .list-values:nth-child(odd) {
  background: white;
  padding: var(--sm-space);
  margin-bottom: 0;
}

.list-key:nth-child(even), .list-values:nth-child(even) {
  background: #e9e9e9;
  margin-bottom: 0;
  padding: var(--sm-space);
}

.review-info-conf {
  padding: 0 var(--md-space) 0;
}

/* .review-info-conf span:nth-of-type(even) {
  color: rgb(128, 0, 0);
} */

/* @media screen and (min-width: 992px) {
  .review-info-conf {
  display: flex;
  }
} */
