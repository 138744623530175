
/* REVIEW DOCUMENTS PAGE */
.attn-docs, .pending-docs {
  background: #e3e3e3;
  padding: var(--sm-space);
  border-radius: var(--xs-space);
  /* margin-bottom: var(--lg-space); */
  /* box-shadow: 0 0 3px #e3e3e3; */
}

.attn-docs p,
.pending-docs p {
  margin-bottom: 0;
}






/* FINAL DOCUMENTS PAGE */
.docs-wrapper {
  background: white;
  padding: var(--md-space);
  margin-bottom: var(--lg-space);
}

.docs-wrapper li a {
  margin: 0 0 var(--sm-space) var(--sm-space);
  width: 50%;
}

/* .doc-btn-box {
  max-width: 540px;
  margin: auto;
} */



